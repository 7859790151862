/***********************/
/*     MOBILE     */
/***********************/
  .navbar {
    display: none;
  }

  .mobile {
    height: 15vh;
  }

  .mobile img {
    width: 2.5em;
    position: absolute;
    left: 1.5em;
    top: 2em;
  }

  .navbar .desktop {
    display: none;
  }

  #overlay-button {
    position: absolute;
    right: 1.2em;
    top: 1.4em;
    z-index: 200;
    padding: 0.5em;
    cursor: pointer;
    user-select: none;
  }
  
  #overlay {
    height: 100vh;
    width: 100vw;
    background: #ffffffd7;
    z-index: 100;
    top: 0;
    position: fixed;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      height: 50vh;
      padding-left: 0;
      list-style-type: none;
      li {
        padding: 1em;
        a {
          color: #545454;
          text-decoration: none;
          font-size: 1.5em;
          font-weight: 500;
        }
      }
    }
  }  

  /***********************/
  /*     TABLET     */
  /***********************/
  @media only screen and (min-width: 768px) {
    .navbar {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 5% 5% 0 5%;
      position: fixed;
      top: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      width: 100%;
      background: white;
      z-index: 100;
      transition: all 0.3s ease; 
      padding: 0;
    }

    .mobile {
      display: none;
    }
    
    .navbar img {
      width: 2.7em;
      padding-left: 10%;
    }

    .navbar .desktop {
      display: block;
      text-align: right;
      padding-right: 10%;
    }
    
    .navbar .desktop a {
      line-height: 310%;
      padding-left: 3em;
      text-decoration: none;
      outline: 0;
      color: #545454;
      font-weight: 500;
      font-size: 0.8em;
    }
  
    .navbar .desktop a:hover {
      color: #A1A1A1;    
    }      
  }
  
  /***********************/
  /*     LAPTOP S     */
  /***********************/
  @media only screen and (min-width: 1024px) {
    .navbar img {
      width: 2.5em;
    }
  }
  
  
  /***********************/
  /*     LAPTOP M    */
  /***********************/
  @media only screen and (min-width: 1330px) {
    .navbar .desktop a {
      font-size: 1em;
    }
    
    .navbar img {
      width: 3.2em;
    }
  }


  /***********************/
  /*  LAPTOP 2560 x 1600    */
  /***********************/
  @media only screen and (min-width: 2560px) {
    .navbar .desktop a {
      font-size: 2em;
    }
    
    .navbar img {
      width: 5em;
    }
  }