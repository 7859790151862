/***********************/
/*     MOBILE     */
/***********************/
  
  footer {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    position: relative;
    justify-items: center;
    align-items: center;
    bottom: 0;
    font-size: 0.6em;
    height: 9vh;
    font-weight: 400;
    color: #545454;
    padding-bottom: 5%;
  }
  
  footer nav ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-between;
    width: 60vw;
    padding: 0;
    cursor: pointer;
    margin: 0
  }
  
  footer nav ul a {
    padding: 0;
    text-decoration: none;
    outline: 0;
    color: #545454;
  }
  
  footer #copyright {
    padding-top: 0.2em;
  }
  
  footer img {
    width: 8em;
  }
  
  /***********************/
  /*     TABLET     */
  /***********************/
  @media only screen and (min-width: 768px) {
  
    footer {
      font-size: 0.7em;
      height: 7vh;
      padding-bottom: 1%;
    }

    footer #copyright {
      padding-top: 0.4em;
    }  
    
    footer img {
      width: 7em;
      padding: 0.4em 0;
    }
  }
  
  /***********************/
  /*     LAPTOP S     */
  /***********************/
  @media only screen and (min-width: 1024px) {
    
    footer {
      font-size: 0.8em;
      grid-template-rows: none;
      grid-template-columns: 2fr 1fr 2fr;
      padding-bottom: 0;
    }
  
    footer nav ul {
      width: 20vw;
    }
  
    footer img {
      width: 6em;
    }  
  }
  
  
  /***********************/
  /*     LAPTOP M    */
  /***********************/
  @media only screen and (min-width: 1330px) {    
    footer {
      font-size: 0.9em;
    }
  }

  /***********************/
  /*  LAPTOP 1920 x 1080    */
  /***********************/
  @media only screen and (min-width: 1920px) {
    footer {
      font-size: 1.2em;
    }
  }

  /***********************/
  /*  LAPTOP 2560 x 1600    */
  /***********************/
  @media only screen and (min-width: 2560px) {
    footer {
      font-size: 1.7em;
    }
  }