.servicesWrapper {
    text-align: center;
    margin: 4em 0 7em 0;
}

.panelsWrapper {
    margin: 5em 1em;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 15px rgba(176, 176, 176, 0.25);
    border-radius: 10px;
    margin: 2em 0 0 0;
    padding: 2em 1em;
}

.cardWrapper .title{
    font-size: 1.2em;
    color: #6B6B6B;
}

.cardWrapper .content {
    font-size: 0.8em;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.03em;
    list-style: disc;
    text-align: left;
    padding: 0 0.7em 0 3.2em;
    margin: 1em 0 0 0;
    color: #545454;
}

.cardWrapper .hoverTitle {
    display: none;
}

.cardWrapper li {
    padding: 0.5em 0;
}

#SDP {
    grid-area: first;
}

#FPA {
    grid-area: second;
}

#CB {
    grid-area: third;
}

#PEPA {
    grid-area: fourth;
}

#SDP .title {
    padding: 0 3.4em;
}

#FPA .title {
    padding: 0 1em;
}       

#PEPA .title {
    padding: 0 1.5em;
}

    @media only screen and (min-width: 375px) {
        .cardWrapper .content {
            padding: 0 1.5em 0 3.5em;
        }

        #SDP .title {
            padding: 0 4.9em;
        }
        
        #FPA .title {
            padding: 0 2.6em;
        }       
        
        #PEPA .title {
            padding: 0 3em;
        }
    }

    @media only screen and (min-width: 425px) {
        .cardWrapper .content {
            padding: 0 1.8em 0 4.3em;
        }

        #SDP .title {
            padding: 0 6.2em;
        }
        
        #FPA .title, #PEPA .title {
            padding: 0 4em;
        }
    }

    /***********************/
    /*     TABLET     */
    /*********************/
    @media only screen and (min-width: 768px) {
        .servicesWrapper {
            padding-top: 25vh;
            margin: 0em 0 15em 0;
        }
        
        .panelsWrapper {
            margin: 7em 9em;
        }

        .cardWrapper .content {
            padding: 0 2.5em 0 6em;
        }

        .cardWrapper .title{
            font-size: 1.2em;
        }

        .cardWrapper .content {
            font-size: 0.9em;
        }        

        #SDP .title {
            padding: 0em;
        }
        
        #FPA .title, #PEPA .title {
            padding: 0 6.5em;
        }         
    }

    /***********************/
    /*     LAPTOP S     */
    /***********************/
    @media only screen and (min-width: 1024px) {
        .panelsWrapper {
            display: grid;
            grid-template-columns: 45% 55%;
            grid-template-rows: 45% 7% 45%;
            grid-template-areas:
                "first second"
                "first fourth"
                "third fourth";        
            gap: 1em;
            column-gap: 1em;
            margin: 5em 12em;
        }
        
        .cardWrapper .title{
            font-size: 1.7em;
            padding: 0;
        }

        .cardWrapper .content {
            display: none;
            padding: 0 0 0 2em;
            font-size: 0.9em;
        }

        .cardWrapper:hover .title{
            display: none;
        }

        .cardWrapper:hover .content{
            display: block;
        } 

        .cardWrapper:hover .hoverTitle {
            display: block;
            font-family: 'Zilla Slab';
            color: #545454;
            font-size: 1.1em;
            text-align: left;
            margin: 0;
            width: 100%;
            padding: 5% 0 0 7%;
        }

        .cardWrapper li  {
            padding: 0 0 1em 0;
        }

        #FPA .content, #PEPA .content {
            padding-left: 2.2em;
        }

        #SDP {
            height: 40vh;
        }
        
        #SDP:hover {
            background-color: #FFF6F6;
        }

        #SDP .title {
            padding: 0 1.5em;
        }

        #FPA {
            height: 31.5vh;
        }
        
        #FPA:hover {
            background-color: #F6F7FF;
        }

        #FPA .title {
            padding: 0 1em;
        }        

        #CB {
            height: 20.7vh;
        }
        
        #CB:hover {
            background-color: #F6F7FF;
        }

        #PEPA {
            height: 29vh;
        }
        
        #PEPA:hover {
            background-color: #FFFBEF;
        }

        #PEPA .title {
            padding: 0 1em;
        }
    }

    /***********************/
    /*     LAPTOP M    */
    /***********************/
    @media only screen and (min-width: 1330px) {
        .panelsWrapper {
            margin: 5em 20em;
        }
        
        .cardWrapper .title{
            font-size: 1.8em;
        }

        .cardWrapper:hover .hoverTitle {
            font-size: 1.2em;
        }

        .cardWrapper .content {
            font-size: 1em;
        }

        #SDP {
            height: 36.5vh;
        }

        #CB {
            height: 18.7vh;
        }

        #FPA {
            height: 28.5vh;
        }

        #PEPA {
            height: 26.5vh;
        }

        #SDP .title, #PEPA .title {
            padding: 0 2.2em;
        }

        #FPA .title{
            padding: 0 2.4em;
        }

        .cardWrapper li{
            padding-top: 0em;
            padding-bottom: 1em;
        }

        #SDP .content {
            padding-left: 2em;
        }
    }

    /***********************/
    /*  LAPTOP 2560 x 1600    */
    /***********************/
    @media only screen and (min-width: 2560px) {
        .panelsWrapper {
            margin: 5em 30em;
        }
        
        .cardWrapper .title{
            font-size: 3.5em;
        }

        .cardWrapper:hover .hoverTitle {
            font-size: 2.5em;
        }

        .cardWrapper .content {
            font-size: 2em;
        }

        #SDP {
            height: 37vh;
        }

        #CB {
            height: 27vh;
        }

        #FPA {
            height: 30.5vh;
        }

        #PEPA {
            height: 33.5vh;
        }

        #SDP .title, #PEPA .title {
            padding: 0 2.9em;
        }

        #FPA .title{
            padding: 0 2.5em;
        }

        .cardWrapper li{
            padding-top: 0em;
            padding-bottom: 1em;
        }

        #SDP .content {
            padding-left: 2em;
        }
    }