/***********************/
/*     MOBILE     */
/***********************/
    
.contactWrapper {
    text-align: center;
    margin: 4em 0 5em 0;
}

#two-col-two-rows {
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 5em 0.8em 0 0.8em;
}

#contactCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 15px rgba(176, 176, 176, 0.25);
    border-radius: 10px;
    padding: 5% 0;
    margin: 5% 0;
    height: 15vh;
    justify-content: center;
}

#contactCard h1 {
    font-family: 'Jost';
    font-size: 0.9em;
    font-weight: 500;
    color: #A1A1A1;
    letter-spacing: 0.03em;
}

#contactCard a {
    margin: 0;
    font-size: 1em;
    font-family: 'Zilla Slab';
    color: #6B6B6B;
    font-weight: 700;
    padding: 2% 12% 0 12%;
    line-height: 100%;
    text-decoration: none;
}

    /***********************/
    /*     TABLET     */
    /***********************/
    @media only screen and (min-width: 768px) {
        .contactWrapper {
            margin: 0 0 5em 0;
            padding-top: 25vh;
        }
        
        #two-col-two-rows {
            grid-template-columns: 1fr 1fr;
            margin: 8em 7em;
            grid-column-gap: 1.2em
        }
    }

    /***********************/
    /*     LAPTOP S     */
    /***********************/
    @media only screen and (min-width: 1024px) {
        #two-col-two-rows {
            margin: 8em 11em;
        }

        #contactCard {
            height: 20vh;
        }      

        #contactCard h1 {
            font-size: 1em;
        }
        
        #contactCard a {
            font-size: 1.2em;
            padding: 2% 14% 0 14%;
        }        
    }

    /***********************/
    /*     LAPTOP M    */
    /***********************/
    @media only screen and (min-width: 1330px) {
        #two-col-two-rows {
            margin: 9em 18em;
        }

        #contactCard h1 {
            font-size: 1.2em;
        }
        
        #contactCard a {
            font-size: 1.5em;
        }        
    }    


    /***********************/
    /*  LAPTOP 2560 x 1600    */
    /***********************/
    @media only screen and (min-width: 2560px) {
        #two-col-two-rows {
            margin: 9em 30em;
        }

        #contactCard h1 {
            font-size: 2.5em;
        }
        
        #contactCard a {
            font-size: 3em;
        }  
    }