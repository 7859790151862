/***********************/
/*     MOBILE     */
/***********************/

#pageHeader h1 {
  font-family: 'Zilla Slab Highlight';
  font-size: 2.6em;
}

#pageHeader h2 {
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 0.03em;
}

#pageHeader #About, #pageHeader #Contacts, #pageHeader #Services {
  padding: 1% 5% 0 5%;
}

#pageHeader img {
  height: 3vh;
}
  /***********************/
  /*     TABLET     */
  /***********************/
  @media only screen and (min-width: 768px) {
    #pageHeader h1 {
      font-size: 3.5em;
    }

    #pageHeader h2 {
      font-size: 0.9em;
    }

    #pageHeader #About {
      padding: 1% 23% 0 23%;
    }  
    
    #pageHeader #Contacts {
      padding: 1% 20% 0 20%;
    }

    #pageHeader #Services {
      padding: 1% 27% 0 27%;
    }

    #pageHeader img {
      height: 3.5vh;
    }    
  }
  
  /***********************/
  /*     LAPTOP S     */
  /***********************/
  @media only screen and (min-width: 1024px) {
    
    #pageHeader h1 {
      font-size: 3.7em;
    }

    #pageHeader h2 {
      font-size: 0.9em;
    }
    
    #pageHeader #About, #pageHeader #Contacts {
      padding: 1% 30% 0 30%;
    }  

    #pageHeader #Services {
      padding: 1% 34% 0 34%;
    }

  }
  
  
  /***********************/
  /*     LAPTOP M    */
  /***********************/
  @media only screen and (min-width: 1330px) {
    #pageHeader h1 {
      font-size: 3.9em;
    }
    #pageHeader h2 {
      font-size: 1.1em;
    }

    #pageHeader #About,  #pageHeader #Contacts {
      padding: 1% 32% 0 32%;
    }

    #pageHeader #Services {
      padding: 1% 36% 0 36%;
    }

    #pageHeader img {
      height: 4vh;
    }  
  }

  /***********************/
  /*  LAPTOP 2560 x 1600    */
  /***********************/
  @media only screen and (min-width: 2560px) {
    #pageHeader h1 {
      font-size: 6em;
    }
    
    #pageHeader h2 {
      font-size: 2em;
    }

    #pageHeader img {
      height: 3vh;
      padding-top: 1%;
    } 
  }