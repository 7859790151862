/***********************/
/*     MOBILE     */
/***********************/

.aboutWrapper {
    text-align: center;
    margin: 4em 0 6em 0;
}

#clientList {
    margin: 8em 0.8em 0 0.8em;
    padding: 10% 0;
    box-shadow: 0px 2px 15px rgba(176, 176, 176, 0.25);
    border-radius: 10px;  
}

#clientList h2 {
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 0.03em;   
    margin-top: 0;
    line-height: 100%;
    padding: 0 15%
}

.two-column-table {
    display: grid;
    font-family: 'Zilla Slab';
    color: #545454;
    font-weight: 500;
    font-size: 0.8em;
}

.two-column-table ul img {
    display: none;
}

.two-column-table ul {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
}

.two-column-table ul li {
    padding-top: 4%;
}

.aboutSubSection {
    margin: 8em 0.8em 0 0.8em;
}

.aboutSubSection h1 {
    font-weight: 700;
    font-size: 1.5em;
}

.aboutSubSection h2 {
    font-weight: 700;
    font-size: 1.7em;
    letter-spacing: 0.03em;
    color: #545454;
    margin: 1.5em 0 0 0 ;
}

.aboutSubSection h3 {
    font-weight: 400;
    letter-spacing: 0.03em;
    font-size: 0.9em;
    padding: 1% 0;
    margin: 0;
    color: #545454;
}

.aboutSubSection p {
    font-weight: 500;
    color: #A1A1A1;
    letter-spacing: 0.03em;
    font-size: 0.8em;
}
    /***********************/
    /*     TABLET     */
    /***********************/
    @media only screen and (min-width: 768px) {
        .aboutWrapper {
            margin: 0 0 10em 0;
            padding-top: 25vh;
        }
        
        #clientList {
            margin: 10em 7em;
            padding: 5% 0;
        }
        
        #clientList h2 {
            font-size: 1em;
            margin-bottom: 1.5em;
        }        

        .two-column-table {
            grid-template-columns: 1fr 1fr;
            padding: 0 9%;
            font-size: 0.8em;
        }

        .two-column-table ul img {
            display: inline-block;
            height: 1.4vh;
        }

        .two-column-table ul {
            text-align: left;
            padding-left: 15%;
        }

        .aboutSubSection {
            margin: 10em 7em 0 7em;
        }
        
        .aboutSubSection h1 {
            font-size: 1.7em;
        }
        
        .aboutSubSection p {
            font-size: 0.9em;
        }        
    }
    
    /***********************/
    /*     LAPTOP S     */
    /***********************/
    @media only screen and (min-width: 1024px) {
        #clientList {
            margin: 8em 15em;
        }

        .aboutSubSection {
            margin: 10em 15em 0 15em;
        }        
    }
    
    
    /***********************/
    /*     LAPTOP M    */
    /***********************/
    @media only screen and (min-width: 1330px) {
        #clientList {
            margin: 10em 20em;
        }

        #clientList h2 {
            font-size: 1.3em;
            margin-bottom: 1em;
        }        

        .two-column-table {
            font-size: 1em;
            padding: 0 13%;
        }

        .two-column-table ul img {
            height: 1.7vh;
        }

        .aboutSubSection {
            margin: 15em 20em 0 20em;
        }        

        .aboutSubSection h1 {
            font-size: 1.9em;
        }

        .aboutSubSection h2 {
            font-size: 2em;
        }        
        
        .aboutSubSection h3 {
            font-size: 1.2em;
        }     

        .aboutSubSection p {
            font-size: 1.1em;
        }               
    }

    /***********************/
    /*  LAPTOP 2560 x 1600    */
    /***********************/
    @media only screen and (min-width: 2560px) {
        #clientList {
            margin: 10em 40em;
        }

        #clientList h2 {
            font-size: 2em;
        }        

        .two-column-table {
            font-size: 1.8em;
            padding: 0 10%;
        }

        .aboutSubSection {
            margin: 15em 40em;
        }        

        .aboutSubSection h1 {
            font-size: 3em;
        }

        .aboutSubSection h2 {
            font-size: 3.5em;
        }        
        
        .aboutSubSection h3 {
            font-size: 2em;
        }     

        .aboutSubSection p {
            font-size: 2em;
        }  
    }