/***********************/
/*     MOBILE     */
/***********************/

h1 {
    margin: 0;
    font-family: Zilla Slab;
    font-style: normal;
    font-weight: 700;
    font-size: 2.2em;
    color: #3E3E3E;
    line-height: 100%;
}

h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #A1A1A1;
}

#cube {
    position: relative;
    margin: 20px auto;
    height: 30px;
    width: 30px;
    animation: 4s rotateforever infinite linear;
    transform-style: preserve-3d;
}
  
.face {
    position: absolute;
    height: 30px;
    width: 30px;
}

#cube .face img {
    width: 100%;
}

.one {
    transform: rotateX(-54.7356deg) rotateY(45deg) translateZ(15px);
}

.two {
    transform: rotateX(-54.7356deg) rotateY(135deg) translateZ(15px);
}

.three {
    transform: rotateX(-54.7356deg) rotateY(225deg) translateZ(15px);
}

.four {
    transform: rotateX(-54.7356deg) rotateY(315deg) translateZ(15px);
}

.five {
    transform: rotateX(35.2644deg) rotateZ(-45deg) translateZ(15px);
}

.six {
    transform: rotateX(35.2644deg) rotateY(180deg) rotateZ(-45deg) translateZ(15px);
}

@keyframes rotateforever {
    to {
        transform: rotateY(360deg);
    }
}

/***********************/
/*     TABLET     */
/***********************/
@media only screen and (min-width: 768px) {
h1 {
    font-size: 2.8em;
    line-height: 100%;
    padding: 0 15%;
}

h2 {
    font-size: 1em;
}
}

/***********************/
/*     LAPTOP S    */
/***********************/
@media only screen and (min-width: 1024px) {
    h1 {
        font-size: 3em;
        padding: 0 25%;
    }
    
    h2 {
        font-size: 1em;
    }
}

/***********************/
/*     LAPTOP M    */
/***********************/
@media only screen and (min-width: 1330px) {
    h1 {
        font-size: 3.8em;
        padding: 0 30%;
    }

    h2 {
        font-size: 1.2em;
    }
}

/***********************/
/*  LAPTOP 2560 x 1600    */
/***********************/
@media only screen and (min-width: 2560px) {
    h1 {
        font-size: 7em;
    }

    h2 {
        font-size: 3em;
        margin: 0.2em;
    }

    #cube {
        margin: 60px auto;
        height: 60px;
        width: 60px;
    }
      
    .face {
        position: absolute;
        height: 60px;
        width: 60px;
    }
    
    .one {
        transform: rotateX(-54.7356deg) rotateY(45deg) translateZ(30px);
    }
    
    .two {
        transform: rotateX(-54.7356deg) rotateY(135deg) translateZ(30px);
    }
    
    .three {
        transform: rotateX(-54.7356deg) rotateY(225deg) translateZ(30px);
    }
    
    .four {
        transform: rotateX(-54.7356deg) rotateY(315deg) translateZ(30px);
    }
    
    .five {
        transform: rotateX(35.2644deg) rotateZ(-45deg) translateZ(30px);
    }
    
    .six {
        transform: rotateX(35.2644deg) rotateY(180deg) rotateZ(-45deg) translateZ(30px);
    }    
  }
  