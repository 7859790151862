/***********************/
/*     MOBILE     */
/***********************/

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homeWrapper {
  text-align: center;
  margin: 9em 0 12.5em 0;
}

/***********************/
/*     TABLET     */
/***********************/
@media only screen and (min-width: 768px) {
  .homeWrapper {
    padding-top: 20vh;
    margin: 7em 0 14em 0;
  }
}

/***********************/
/*     LAPTOP 1024 x 768    */
/***********************/
@media only screen and (min-width: 1024px) {
  .homeWrapper {
    padding-top: 12vh;
    margin: 12em 0 13.5em 0;
  }
}

/***********************/
/*  LAPTOP  1330 x 768   */
/***********************/
@media only screen and (min-width: 1330px) {
  .homeWrapper {
    margin: 10em 0 14em 0;
  }
}

/***********************/
/*  LAPTOP 1440 x 900    */
/***********************/
@media only screen and (min-width: 1440px) {
  .homeWrapper {
    margin: 14em 0 16em 0;
  }
}

/***********************/
/*  LAPTOP 1920 x 1080    */
/***********************/
@media only screen and (min-width: 1920px){
  .homeWrapper {
    margin: 14em 0 16.5em 0;
  }
}

/***********************/
/*  LAPTOP 2560 x 1600    */
/***********************/
@media only screen and (min-width: 2560px) {
  .homeWrapper {
    margin: 23em 0 28em 0;
  }
}

